
export default {
	name: "crcParticulars",
	data() {
		return {
			sex: "girl",
			newEdit: false,
			name: "",
			years: "",
			list: "",
			education: "",
			major: "",
			age: "",
			proCount: "",
			inCount: "",
			img: "",
			user: {},
			projectSelect: '',//项目工作量统计下拉框
			projectSelectList: [],//项目工作量统计下拉框列表
			mounthProjectSelect: '',//月度工作趋势图
			tabelTitleList: [],//项目工作量统计title
			crcWorkList: [],//项目工作量统计内容
		};
	},

	mounted() {
		this.drawLine();
	},

	created() {
		this.token = this.getCookie("token");
		this.findShortNameByCrc()
		this.getCrcParticulars();
	},

	methods: {
		getCrcParticulars() {
			var _this = this;
			_this.$http.post("/API/PLAT/crcUserInfo", { id: this.$route.params.id }
			    , { headers: { token: _this.token } }
			)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.user = response.data.data
					}
				});
		},

		findShortNameByCrc() {
			var _this = this;
			_this.$http.post( "/API/PLAT/findShortNameByCrc", { crcId: this.$route.params.id }
			    , { headers: { token: _this.token } }
			).then(function (response) {
					if (response.data.code == 200) {
						_this.mounthProjectSelect = response.data.data.projectArray[0].projectId;
						_this.projectSelect = response.data.data.projectArray[0].projectId;
						_this.projectSelectList = response.data.data.projectArray;
						_this.projectSelectChange(_this.mounthProjectSelect)
					}
				});
		},

		toCrcMena() {
			//    this.$router.push("/orCrcManagement");
			this.$router.go(-1)
		},
		toPro(id) {
			this.$router.push({ name: "projectParticulars", params: { id: id } });
		},
		drawLine() {
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			// let echar1 = this.$echarts.init(document.getElementById("echar-1"))
			let echar = this.$echarts.init(document.getElementById("echar"));
			// 绘制图表
			_this.$http.post(
				"/API/PRO/selectCRCUserScore", {
				CRCUserId: this.$route.params.id
			}, {
				headers: {
					token: _this.token
				}
			}
			)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.getEchar(response.data.data.user)
					}
				});
			_this.$http.post(
				"/API/PLAT/findEachProjectByCrc", {
				crcId: this.$route.params.id
			}, {
				headers: {
					token: _this.token
				}
			}
			)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.getEchar1(response.data.data)
					}
				});
			_this.$http.post("/API/PLAT/findShortNameByCrc", {
				crcId: this.$route.params.id
			}, {
				headers: {
					token: _this.token
				}
			}
			)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.$http.post("/API/PLAT/findMonthJobTrend", {
							crcId: _this.$route.params.id,
							projectId: response.data.data.projectArray[0].projectId
						}, {
							headers: {
								token: _this.token
							}
						})
							.then(function (res) {
								if (res.data.code == 200) {
									_this.getEchar2(res.data.data)
								}
							});
					}
				});

		},

		getEchar(data) {
			let echar = this.$echarts.init(document.getElementById("echar"));
			echar.setOption({
				radar: {
					//						        shape: 'circle',
					center: ["50%", "50%"],
					radius: "50%",
					name: {
						textStyle: {
							color: "#333"
						}
					},
					splitArea: {
						areaStyle: {
							color: "#D7F6FB"
						}
					},
					indicator: [
						{ name: "职业素养", max: 100 },
						{ name: "沟通与协调", max: 100 },
						{ name: "学习与接受", max: 100 },
						{ name: "整理与完善", max: 100 },
						{ name: "执行与完成", max: 100 }
					],
					axisLine: {
						lineStyle: {
							color: "#fff"
						}
					},
					splitLine: {
						lineStyle: {
							color: "#fff"
						}
					}
				},
				series: {
					type: "radar",
					data: [{
						value: [
							data.avgGgligt,
							data.avgSqfmyd,
							data.avgXmzgpf,
							data.avgYjzxt,
							data.avgYygl
						],
						areaStyle: {
							normal: {
								color: "#5BD0E2"
							}
						},
						itemStyle: {
							normal: {
								color: "#5BD0E2"
							}
						}
					}]
				}
			});
		},

		getEchar1(data) {
			let echar1 = this.$echarts.init(document.getElementById('echar-1'))
			echar1.setOption({
				tooltip: {
					trigger: 'item',
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				series: [{
					name: '访问来源',
					type: 'pie',
					radius: '55%',
					center: ['50%', '60%'],
					data: data.projectArray,
					itemStyle: {
						emphasis: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						},
						normal: {
							color: function (params) {
								//自定义颜色
								var colorList = [
									'#43B3EC', '#6DE0E2', '#FEDA66', '#FD9F82', '#A1E5B9', '#3DC5E7',
								];
								return colorList[params.dataIndex]
							}
						}
					}
				}]
			})
		},
		getEchar2(data) {
			let echar2 = this.$echarts.init(document.getElementById('echar-2'))
			echar2.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: { // 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
				},
				legend: {
					data: ['工作量', '超窗次数', '超窗警戒']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: data.x,
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 100,
					interval: 20,
					boundaryGap: [0, 0.01],
					axisLine: {
						lineStyle: {
							color: '##CBD1DA'
						}
					}
				},
				series: [{
					name: '工作量',
					type: 'bar',
					barWidth: '25%',
					color: '#61C7D7',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y1
				},
				{
					name: '超窗次数',
					type: 'bar',
					barWidth: '25%',
					color: '#FFE188',
					label: {
						normal: {
							show: true,
							position: 'insideRight'
						}
					},
					data: data.y2
				},
				{
					name: '超窗警戒',
					type: 'line',
					color: '#FD9F83',
					data: data.y3
				},
				]
			});
		},

		tableRowClassName({ row, rowIndex }) {
			if (rowIndex === 1) {
				return "warning-row";
			} else if (rowIndex === 3) {
				return "success-row";
			}
			return "";
		},

		projectSelectChange(id) {
			var _this = this;
			_this.$http.post(
				"/API/PLAT/findJobNumByPatAndPro", {
				crcId: _this.$route.params.id,
				projectId: id
			}, {
				headers: {
					token: _this.token
				}
			}
			)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.tabelTitleList = response.data.data.projectArray[0];
						_this.crcWorkList = response.data.data.projectArray;
						_this.crcWorkList.splice(0, 1)
					}
				});
		},

		mounthProjectSelectChange(id) {
			var _this = this;
			_this.$http.post(
				"/API/PLAT/findMonthJobTrend", {
				crcId: _this.$route.params.id,
				projectId: id
			}, {
				headers: {
					token: _this.token
				}
			}
			)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.getEchar2(response.data.data)
					}
				});
		},

		//跳转受试者详情
		toSubjects(item) {
			var _this = this;
			_this.setCookie('crcId', this.$route.params.id);//crc名字
			this.$router.push({
				name: 'subjects',
				params: {
					patientId: item[0],
					projectId: _this.projectSelect
				}
			});
		},
	},

	filters: {
		setUserIdNumber: function (val) {
			if (val) {
				return val.substr(0, 14) + "****";
			}
		}
	}
};
